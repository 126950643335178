import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { globals } from 'src/app/globals';
import { ToastService } from 'src/app/services/toast.service';
import { dataService } from 'src/app/services/data.service';
import { apiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-job-schedule',
  templateUrl: './job-schedule.component.html',
  styleUrls: ['./job-schedule.component.scss'],
})
export class JobScheduleComponent implements OnInit {
  @Input() job : any;
  @Output() back = new EventEmitter<any>();
  user: any = {};
  build: any = {};
  jobUserSearch: any = {};
  completedTasks: any;
  validTasks: any;
  state: any = 1;
  signOff: any = {};

  constructor(
    private dataService: dataService,
    private apiService: apiService,
    private router: Router,
    private toastService: ToastService
  ) { }

  addEngineer() {
    this.jobUserSearch = {};
    this.state = 3;
  }

  async searchSystemUsers() {

    const body = {
      uid: this.user.id,
      query: this.jobUserSearch.query
    }
    
    this.jobUserSearch.users = await this.apiService.makeCall('getSystemUsers', body);

  }

  async selectJobEngineer(x: any) {

    const body = {
      uid: this.user.id,
      job: this.job.id,
      engineer: x.id
    }

    await this.apiService.makeCall('addJobEngineer', body);
    if (this.job.engineers) {
      this.job.engineers.push(x);
    }
    else {
      this.job.engineers = [x];
    }
    this.jobUserSearch = {};
    this.state = 1;

  }

  calcValidTasks () {
    if (this.job.tasks) {
      var count = 0;
      for (const value of this.job.tasks) {
        if (value.name.length > 0) {
          count ++
        }
      }
    }
    this.validTasks = count;
  }

  calcCompletedTasks () {
    if (this.job.tasks) {
      var count = 0;
      for (const value of this.job.tasks) {
        if (value.status == 1) {
          count ++
        }
      }
    }
    this.completedTasks = count;
  }

  checkAllTasks() {
    if (this.completedTasks < this.job.tasks.length) {
      for (const value of this.job.tasks) {
        value.status = true;
      }
    }
    else {
      for (const value of this.job.tasks) {
        value.status = false;
      }
    }
    this.calcCompletedTasks();
  }

  checkTask(task: any) {
    if (task.value.status == true) {
      task.value.status = false;
    }
    else {
      task.value.status = true;
    }
    this.calcCompletedTasks();
  }

  signoffJob() {
    this.state = 2;
  }

  async signoffConfirm() {
    const body = {
      uid: this.user.id,
      id: this.job.id,
      notes: this.signOff.actions_taken,
      minutes: this.signOff.minutes,
      type: this.job.type,
      number: this.job.number,
      service_number: this.job.service_number,
      tasks: this.job.tasks,
      title: this.job.title,
    }
    await this.apiService.makeCall('signoffJob', body);
    this.toastService.showToast({ header: 'Success', message: 'Job has been signed off' });
    this.back.emit();
  }

  async followupJob() {
    const body = {
      uid: this.user.id,
      pid: this.job.plant_id,
      lat: 1,
      lng: 2,
      followup: 1,
      oJid: this.job.id
    }

    const job = await this.apiService.makeCall('createJob', body);
    
    this.toastService.showToast({ header: 'Success', message: 'Followup job ' + job.number + ' has been created' });
    this.dataService.setData('job', job);
    this.router.navigate(['dashboard/jobs/job']);
  }
  
  exit() {
    this.state = 1;
  }

  viewFile(url: any) {
    window.open(this.build.cdn + url, '_blank');
  }

  ngOnInit() {
    this.calcValidTasks();
    this.calcCompletedTasks();
    this.user = this.dataService.getData('user');
    this.build = globals;
    this.signOff.actions_taken = this.job.actions_taken;
  }

}