import { Component, Input, OnInit } from '@angular/core';
import { ToastService } from '../../services/toast.service';
import { TimeoutService } from '../../services/timeout.service';
import { dataService } from '../../services/data.service';

@Component({
  selector: 'app-lock-screen',
  templateUrl: './lock-screen.component.html',
  styleUrls: ['./lock-screen.component.scss'],
})
export class LockScreenComponent implements OnInit {
  //@Input() timeout;
  user: any = {};
  unlock: any = {};
  show: any = 0;

  constructor(
    public dataService: dataService,
    public timeoutService: TimeoutService,
    private toastService: ToastService
  ) { }

  unlockUser() {
    if (this.user.app_lock == this.unlock.code) {
      this.show = 0;
      this.timeoutService.resetTimer();
    }
    else {
      this.toastService.showToast({ header: 'Error', message: 'Oops, incorrect pin' });
    }
    this.unlock = {};
  }

  ngOnInit() {
    this.user = this.dataService.getData('user');
    var check = setInterval(() => {
     if (this.timeoutService.getTimeout() <= 0) {
       this.show = 1;
     }
    }, 1000);
  }

}