import { Injectable } from '@angular/core';
import { Observable, throwError, Subject} from 'rxjs';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { LoadingService } from 'src/app/services/loading.service';
import { ToastService } from 'src/app/services/toast.service';
import { dataService } from 'src/app/services/data.service';
import { globals } from 'src/app/globals';

@Injectable()
export class apiService {
  data: any;
  user: any;
  public localVar: any;

  constructor(
    public loadingService: LoadingService,
    public dataService: dataService,
    private readonly http: HttpClient,
    private toastService: ToastService
  ) { }

  makeCall = async (key: any, data: any)  => {

    if (key == 'getDashboard' || key == 'getScheduler') {
      this.loadingService.presentLoading(2.5);
    }
    else if (key == 'recordLocation') {
      
    }
    else {
      this.loadingService.presentLoading(1);
    }
    this.user = this.dataService.getData('user');
    let resBody:any = {};
    let uri = globals.api+'?apiKey='+globals.key+'&apiToken='+this.user.token+'&apiFunctionName='+key; 

    await fetch( uri,{
      method: 'post',
      body: JSON.stringify(data)
    })
    .then(response => response.json())
    .then(fetchResponse => {
      if (fetchResponse.success == true && fetchResponse.count) {
        resBody = fetchResponse;
      }
      else if (fetchResponse.success == true && fetchResponse.url) {
        resBody = fetchResponse.url;
      }
      else if (fetchResponse.success == true ) {
        resBody = fetchResponse.dataArray;
      }
      else {
        this.toastService.showToast({ header: 'Error', message: 'Oops, something went wrong ' + this.user.first_name + ', please try again' });
        return false;
      }
    }).catch((error) => {
      this.toastService.showToast({ header: 'Error', message: 'Oops, something went wrong ' + this.user.first_name + ', please try again' });
      //this.logout();
      return false;
    })
    return resBody;

  }

  async logout() {

    const body = this.dataService.getData('user');
    await this.makeCall('logout', body);
    this.dataService.clearData('user');
    //this.router.navigate(['login']);
    setTimeout(()=>{                           
      window.location.href = window.location.href
    }, 500);

  }

}