import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { HttpClientModule } from '@angular/common/http';
import { dataService } from './services/data.service';
import { apiService } from './services/api.service';
import { ToastService } from './services/toast.service';
import { LoadingService } from './services/loading.service';
import { TimeoutService } from './services/timeout.service';

import { FontAwesomeModule, FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';

import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
// import { SchedulerModule } from 'angular-calendar-scheduler';

import { Geolocation } from '@ionic-native/geolocation/ngx';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

import { NgxEchartsModule } from 'ngx-echarts';

import { ListItemComponent } from 'src/app/components/list-item/list-item.component';
import { LockScreenComponent } from 'src/app/components/lock-screen/lock-screen.component';
import { JobScheduleComponent } from 'src/app/components/job-schedule/job-schedule.component';
import { BarcodeScanner } from '@ionic-native/barcode-scanner/ngx';

@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        BrowserModule,
        FontAwesomeModule,
        IonicModule.forRoot(),
        AppRoutingModule,
        CalendarModule.forRoot({
          provide: DateAdapter,
          useFactory: adapterFactory,
        }),
        HttpClientModule,
        NgxEchartsModule.forRoot({
            echarts: () => import('echarts')
        })
    ],
    providers: [{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy }, dataService, apiService, ToastService, LoadingService, ListItemComponent, LockScreenComponent, TimeoutService, JobScheduleComponent, Geolocation, BarcodeScanner],
    bootstrap: [AppComponent]
})
export class AppModule {
  constructor(
    library: FaIconLibrary,
  ) {
		library.addIconPacks(fas, fab, far);
	}
}