import { Injectable } from '@angular/core';

@Injectable()
export class dataService {
  data: any;

  constructor() { }

  setData(key: any, data: any) {
    window.localStorage.removeItem(key);
    window.localStorage.setItem(key, JSON.stringify(
      data
    ));
    this.data = data;
  }

  getData(key: any) {
    this.data = JSON.parse(window.localStorage.getItem(key));
    return this.data;
  }

  clearData(key: any) {
    window.localStorage.removeItem(key);
    this.data = null;
  }
}