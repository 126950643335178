import { Injectable } from '@angular/core';

@Injectable()
export class TimeoutService {
  data: any;
  interval: any;
  timeout: any = 900000; // 900000;

  constructor() { }

  startTimer() {
    clearInterval(this.interval);
    this.interval = setInterval(() => {
      if (this.timeout < 0) {
        clearInterval(this.interval);
      }
      else {
        this.timeout = (this.timeout - 1000);
      }
    }, 1000);
  }

  getTimeout() {
    return this.timeout;
  }

  resetTimer() {
    this.timeout = 900000;
    this.startTimer();
  }

  zeroTimer() {
    this.timeout = 0;
    clearInterval(this.interval);
  }
}